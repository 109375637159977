.container {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 80px 20px; /* Add horizontal padding */
    margin: 0 auto; 
    max-width: 90%; /* Prevent the container from overflowing */
    box-sizing: border-box; /* Include padding and border in width calculation */
  }
  
  .header {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .subHeader {
    font-size: 18px;
    color: #666;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .contactSection {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contactItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  .label {
    font-size: 16px;
    font-weight: 600;
    color: #555;
  }
  
  .value {
    font-size: 16px;
    color: #007BFF;
    text-decoration: underline;
  }
  
  .guideSection {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .guideHeader {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .guideList {
    padding-left: 20px;
    color: #555;
  }
  
  .guideStep {
    margin-bottom: 10px;
    line-height: 1.6;
    font-size: 16px;
  }
  