.container {
    font-family: 'Roboto', sans-serif;
    padding: 40px 20px;
    background-color: #f9f9f9;
    color: #333;
    line-height: 1.6;
    margin: 0 auto;
    max-width: 1200px;
}

.header {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 40px;
}

.section {
    margin-bottom: 50px;
}

.subHeader {
    font-size: 1.8rem;
    font-weight: bold;
    color: #444;
    margin-bottom: 15px;
}

.text {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

.videoContainer {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.videoPlaceholder {
    width: 100%;
    max-width: 800px;
    height: 450px;
    background-color: #eee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ccc;
}

.videoPlaceholderText {
    font-size: 1.2rem;
    color: #999;
}

.faq-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
}


.faq-container .faq-title {
    font-weight: bold;
    color: #444;
}

.faq-container .faq-row {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
}

.faq-container .faq-content {
    font-size: 0.9rem;
    color: #666;
    padding-top: 10px;
}

@media (max-width: 768px) {
    .header {
        font-size: 2rem;
    }

    .subHeader {
        font-size: 1.5rem;
    }

    .text {
        font-size: 0.9rem;
    }

    .videoPlaceholder {
        height: 250px;
    }

    .faq-container {
        padding: 15px;
    }
}
