.banner-container {
    width: 100%;
    overflow: hidden;
    margin-top: 72px;
  }
  
  .hero-content {
    max-width: 800px;
    width: 100%;
  }
  
  .hero-content h1 {
    color: white;
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 30px;
    line-height: 1.2;
  }
  
  .search-container {
    margin: 30px 0;
  }
  
  .search-box {
    display: flex;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  }
  
  .search-box input {
    flex: 1;
    padding: 20px;
    font-size: 1.1rem;
    border: none;
    outline: none;
  }
  
  .search-box button {
    padding: 0 30px;
    background: #02253b;
    color: white;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .search-box button:hover {
    background: #ffca2c;
  }
  
  .property-types {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  
  .property-types button {
    background: rgba(255, 255, 255, 0.9);
    border: none;
    padding: 15px 30px;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.1rem;
    transition: transform 0.3s, background 0.3s;
  }
  
  .property-types button:hover {
    transform: translateY(-2px);
    background: white;
  }
  
  .featured-section {
    padding: 40px 20px;
  }

  .features-properties {
    width: 80%;
    margin: auto;
  }

  @media (max-width: 800px) {
    .features-properties {
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    .features-properties {
      padding: 0px 20px;
    }
  }

  .recommended-section {
    padding: 40px 20px;
  }

  .recommended-properties {
    width: 80%;
    margin: auto;
    margin-top: 32px;
  }

  .recommended-parts {
    display: flex;
    justify-content: center;
    gap: 24px;
  }

  .category-card-box {
    width: 33.33%;
  }

  @media (max-width: 1024px) {
    .category-card-box {
      width: 50%;
    }
  }

  @media (max-width: 768px) {
    .recommended-properties {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    .recommended-parts {
      flex-direction: column;
      align-items: center;
    }

    .category-card-box {
      width: 60%;
    }
  }

  @media (max-width: 500px) {
    .recommended-parts {
      flex-direction: column;
      align-items: center;
      padding: 0px 20px;
    }

    .category-card-box {
      width: 100%;
    }

    .recommended-properties {
      padding: 0px 20px;
    }
  }
  
  .category-card {
    flex: 1;
    background: white;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid rgb(205, 205, 211);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 15px;
  }
  
  .category-card img {
    width: 100%;
    height: 175px;
    object-fit: cover;
  }
  
  .category-card h3 {
    padding: 16px;
    margin: 0;
    font-size: 20px;
    color: rgb(17, 17, 22);
    font-weight: 900;
    height: 92px;
  }
  
  .category-card p {
    padding: 0 16px 16px;
    margin-top: 8px;
    color: rgb(17, 17, 22);
    height: 84px;
  }
    
  .section-header {
    text-align: center;
  }
  
  .section-header h2 {
    font-size: 2.5rem;
    color: #2a2a2a;
    margin-bottom: 10px;
  }
  
  .section-header p {
    color: #666;
    font-size: 1.1rem;
  }
  
  .property-categories {
    display: flex;
    justify-content: center;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .cta-section {
    background: #006aff;
    color: white;
    padding: 80px 20px;
    text-align: center;
  }
  
  .cta-content {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .cta-content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .cta-content p {
    font-size: 1.1rem;
    margin-bottom: 30px;
    opacity: 0.9;
  }
  
  .cta-button {
    background: white;
    color: #006aff;
    padding: 15px 40px;
    border: none;
    border-radius: 30px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 768px) {
    .hero-content h1 {
      font-size: 2.5rem;
    }
  
    .property-types {
      flex-direction: column;
    }
  
    .property-categories {
      flex-direction: column;
    }
  
    .category-card {
      margin-bottom: 20px;
    }
  }
  /* Banner.css */

/* Container styling */
.recent-properties-container {
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.recent-properties-header {
  text-align: center;
  margin-bottom: 20px;
}

.recent-properties-header h4 {
  color: #333;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.recent-properties-header .text-subtitle {
  color: #777;
  font-size: 1.1em;
}

/* Properties grid */
.recent-properties-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

/* Individual property card */
.recent-property-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  position: relative;
}

.recent-property-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.recent-property-card img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.recent-property-content {
  padding: 15px;
}

.recent-property-content h6 {
  font-size: 1.2em;
  margin-bottom: 8px;
  color: #333;
}

.recent-property-content p {
  font-size: 0.9em;
  margin: 5px 0;
  color: #666;
}

.recent-property-details {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.recent-property-details li {
  margin-right: 15px;
  font-size: 0.9em;
  color: #555;
}

.recent-property-price {
  font-weight: bold;
  color: #4CAF50;
  margin-top: 10px;
}

/* See All button styling */
.see-all-button {
  display: block;
  width: 200px;
  margin: 30px auto;
  padding: 12px;
  background-color: #ffca2c;
  color: white;
  text-align: center;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.see-all-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .recent-properties-grid {
    grid-template-columns: 1fr;
  }

  .see-all-button {
    width: 100%;
  }
}
.recent-properties-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

/* Styles for a single property to make it compact */
.recent-properties-grid.single-property {
  justify-content: center;
  grid-template-columns: 1fr;
}

.recent-property-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.recent-property-card:hover {
  transform: translateY(-5px);
}

.recent-property-content {
  padding: 16px;
}

.property-link img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

.see-all-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.see-all-button:hover {
  background-color: #0056b3;
}
/* General Styling */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}

/* Hero Section */


.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 0 1rem;
  margin: auto;
  top: 35%;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.search-container .search-form {
  display: flex;
  gap: 1rem;
  width: 100%;
  max-width: 600px; /* Limit the width of the search bar */
  margin: 0 auto;
}

.search-input {
  padding: 1rem;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.search-button {
  padding: 1rem 1.25rem; /* Equivalent to px-5 py-3 */
  font-size: 1.125rem; /* Equivalent to text-lg */
  background-color: #02253b; /* Red background */
  transition: background-color 0.3s ease;
  color: white;
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow */
  transition: background-color 0.3s ease; /* Smooth hover transition */
  outline: none;
}

.search-button:hover {
  background-color: #ffca2c; /* Black on hover */
}

.suggestions-dropdown {
  background: white;
  border: 1px solid #ddd;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 5px;
}

.suggestion-item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  color: #000; /* Black text */
  text-align: left;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}
