/* Main container styling */
.search-results-container {
  display: flex;
  flex-direction: column;
  background-color: #f6f7f8;
  margin-top: 77px;
}

/* Main content layout */
.results-container {
  display: flex;
  height: 85vh;
  margin-top: 8px;
}

/* Property list section */
.property-listing {
  flex: 1.5;
  overflow-y: auto;
  background-color: #fff;
  padding: 16px;
  border-right: 1px solid #d4d4d4;
}

.property-listing h1 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.property-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  transition: box-shadow 0.3s, transform 0.3s;
}

.property-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.property-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.property-card .property-content {
  padding: 12px;
}

.property-card h2 {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.property-card p {
  font-size: 14px;
  color: #666;
}

/* Google Maps Section */
.map-container {
  flex: 1;
  height: 100%;
}

@media (max-width: 563px) {
  .filter-line div {
    width: 100%;
  }
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.price-slider {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.slider-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.horizontal-slider {
  width: 100%;
  margin: 10px 0;
}

.slider-thumb {
  height: 16px;
  width: 16px;
  background-color: #02253b;
  border-radius: 50%;
  cursor: pointer;
}

.slider-track {
  background: #d4d4d4;
  height: 4px;
}

.pagination {
  display: flex;
  gap: 16px;
}

.pagination .page-item {
  font-size: 20px;
  border: 1px solid;
  border-color: #374151;
  color: #374151;
  font-weight: 400;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.pagination .page-item:hover {
  color: rgb(28 100 242);
  border-color: rgb(28 100 242);
}

.pagination .active {
  color: rgb(28 100 242);
  border-color: rgb(28 100 242);
}

