.embla {
    max-width: 100%;
    margin: auto;
    --slide-height: 450px;
    --slide-spacing: 1rem;
    --slide-size: 33%;
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 1200px) {
    .embla {
      --slide-size: 50%; /* 2 slides per view */
    }
  }

  @media (max-width: 800px) {
    .embla {
      --slide-size: 60%; /* 2 slides per view */
    }
  }
  
  @media (max-width: 500px) {
    .embla {
      --slide-size: 100%; /* 1 slide per view */
    }
  }
  
  .embla__viewport {
    overflow: hidden;
    width: 100%;
  }
  .embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: -5px;
  }
  .featured_card_slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    height: var(--slide-height);
    min-width: 0;
    overflow: hidden;
    margin: auto;
    padding: 0px 15px;
  }
  
  .embla__controls {
    display: flex;
    justify-content: space-between;
    gap: 1.2rem;
    margin-right: 16px;
  }
  .embla__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
    align-items: center;
  }
  .embla__button {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    padding: 7px;
    margin: 0;
    z-index: 1;
    color: rgb(17, 17, 22);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    border: 1px solid black;
    border-radius: 50%;
  }
  
  .embla__button:disabled {
    color: var(--detail-high-contrast);
  }
  .embla__button__svg {
    width: 35%;
    height: 35%;
  }
  .embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
    gap: 8px;
  }
  .embla__dot {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: black;
    opacity: 0.15;
    box-shadow: none;
  }
  .embla__dot:after {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: "";
    opacity: 1;
  }
  .embla__dot--selected {
    opacity: 0.3;
    width: 24px;
    height: 12px;
  }