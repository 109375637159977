.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.hero-section {
    text-align: center;
    padding: 80px 20px;
    background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)),
                url('../Images/image.png');  /* This will be replaced with the actual image path */
    background-size: cover;
    background-position: center;
    color: white;
    border-radius: 12px;
    margin-bottom: 40px;
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: 700;
}

.hero-section p {
  font-size: 1.2rem;
  opacity: 0.9;
}

.filters-section {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.filter-select {
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  color: #333;
  background-color: white;
  cursor: pointer;
}

.properties-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
}

.property-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 20px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.property-card:hover {
  transform: translateY(-5px);
}

.property-image {
  position: relative;
  height: 250px;
  overflow: hidden;
}

.property-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.property-card:hover .property-image img {
  transform: scale(1.05);
}

.featured-badge {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #ff6b6b;
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.property-info {
  padding: 25px;
}

.property-info h3 {
  margin: 0 0 15px 0;
  font-size: 1.4rem;
  color: #333;
  font-weight: 600;
}

.property-location {
  color: #666;
  margin-bottom: 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.property-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.property-details span {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #555;
  font-size: 0.95rem;
}

.property-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-size: 1.5rem;
  font-weight: 700;
  color: #2c3e50;
}

.price-period {
  font-size: 0.9rem;
  color: #666;
  font-weight: normal;
}

.view-details-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.view-details-btn:hover {
  background-color: #0056b3;
  text-decoration: none;
  color: white;
}

.loading {
  text-align: center;
  padding: 40px;
  font-size: 1.2rem;
  color: #666;
}

@media (max-width: 768px) {
  .hero-section {
    padding: 40px 20px;
  }

  .hero-section h1 {
    font-size: 2rem;
  }

  .filters-section {
    flex-direction: column;
    gap: 15px;
  }

  .properties-grid {
    grid-template-columns: 1fr;
  }
}