/* Messages.css */

.messages-page {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .messages-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .new-conversation-btn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
  }
  
  .messages-container {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .conversations-list {
    flex: 1;
    max-width: 300px;
    border-right: 1px solid #ddd;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .conversation-item {
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .conversation-item.selected {
    background-color: #e1f5fe;
  }
  
  .message-view {
    flex: 2;
    padding: 20px;
  }
  
  .messages-list {
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  
  .message {
    max-width: 70%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
  }
  
  .message.sent {
    align-self: flex-end;
    background-color: #DCF8C6;
  }
  
  .message.received {
    align-self: flex-start;
    background-color: #f1f0f0;
  }
  
  .extend-offer-btn {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Offer Popup */
  .offer-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .offer-popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
  }
  
  .popup-buttons {
    margin-top: 10px;
  }
  
  .popup-buttons button {
    margin: 0 5px;
    padding: 8px 16px;
  }
  /* Offer Popup */
  .offer-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .offer-popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
  }
  
  .popup-buttons {
    margin-top: 10px;
  }
  
  .popup-buttons button {
    margin: 0 5px;
    padding: 8px 16px;
  }
  .unread-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: blue;
    border-radius: 50%;
    margin-left: 5px;
  }
  
  .timestamp {
    display: block;
    font-size: 0.8em;
    color: gray;
    text-align: right;
  }
  
  /* Add this CSS to the relevant stylesheet */
.messages-toggle {
    position: fixed; /* Makes it stay in a fixed position */
    bottom: 20px;    /* Adjust to your desired location */
    right: 20px;     /* Adjust to your desired location */
    z-index: 9999;   /* Ensures it is above all other elements */
    background-color: #fff; /* Optional: Background color */
    border: 1px solid #ccc; /* Optional: Border styling */
    padding: 10px;          /* Optional: Padding for aesthetics */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow */
    border-radius: 5px;     /* Optional: Rounded corners */
  }
  