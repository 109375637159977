.my-listings-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.listings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.listings-header h1 {
  margin: 0;
  color: #333;
}

.create-listing-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.create-listing-btn:hover {
  background-color: #0056b3;
  text-decoration: none;
  color: white;
}

.listings-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.listing-card {
  display: flex;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
}

.listing-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.listing-info {
  padding: 20px;
  flex: 1;
}

.listing-info h2 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 1.5rem;
}

.address {
  color: #666;
  margin-bottom: 15px;
}

.property-details {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.property-details span {
  color: #555;
  font-size: 0.9rem;
}

.price {
  font-size: 1.2rem;
  color: #007bff;
  font-weight: bold;
  margin-bottom: 15px;
}

.listing-actions {
  display: flex;
  gap: 10px;
}

.view-btn, .edit-btn {
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.view-btn {
  background-color: #007bff;
  color: white;
}

.edit-btn {
  background-color: #6c757d;
  color: white;
}

.view-btn:hover, .edit-btn:hover {
  opacity: 0.9;
  text-decoration: none;
  color: white;
}

.no-listings {
  text-align: center;
  padding: 40px;
  background: white;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #666;
}

@media (max-width: 768px) {
  .listing-card {
    flex-direction: column;
  }

  .property-details {
    flex-wrap: wrap;
  }
} 
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Edit Modal */
.edit-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.edit-modal h2 {
  margin-top: 0;
}

.edit-listing-form .form-group {
  margin-bottom: 15px;
}

.edit-listing-form .form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.edit-listing-form .form-group input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.save-btn,
.cancel-btn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.save-btn {
  background-color: #28a745;
  color: #02253b;
  border: none;
}

.cancel-btn {
  background-color: #dc3545;
  color: #fff;
  border: none;
}
