/* History container and property grid styling */
.history-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .history-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .property-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  /* Property card styling */
  .property-card {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .property-card:hover {
    transform: scale(1.02);
  }
  
  .property-card img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
  }
  
  /* Sold badge styling */
  .sold-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 0.9em;
    border-radius: 4px;
  }
  
  .property-details {
    padding: 15px;
  }
  
  .property-details h3 {
    font-size: 1.2em;
    color: #333;
    margin: 10px 0;
  }
  
  .property-details p {
    color: #666;
    margin: 8px 0;
  }
  
  .property-details ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
  }
  
  .property-details li {
    font-size: 0.9em;
    color: #555;
  }
  
  /* Price styling */
  .property-price {
    font-weight: bold;
    color: #2a9d8f;
    margin: 10px 0;
  }
  
  /* View Property button */
  .property-details a {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #2a9d8f;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .property-details a:hover {
    background-color: #21867a;
  }

  