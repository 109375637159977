/* Navbar Styling */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  transition: all 0.3s ease;
  background-color: white; /* Default background */
  box-shadow: none;
}

/* Default (non-scrolled) navbar */
.navbar-default {
  background-color: white;
  width: 100%;
  border-radius: 0;
}

/* Buttons */
.navbar .button {
  background-color: #ffc107; /* Yellow */
  color: #02253b;
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.navbar .button:hover {
  background-color: #ffca2c;
}
li.relative .coming-soon-emblem {
  transform: translateY(-50%);
}

li.relative:hover .coming-soon-emblem {
  display: block;
  opacity: 1;
}

